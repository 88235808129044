import React from 'react';
import { graphql } from "gatsby";
import { GenericPage } from '../components/shared/generic-page';

import '../styles/static-pages.css';

const breadcrumbs = [
  {
    text: 'Начало',
    link: '/',
  },
  {
    text: 'Общи условия',
    link: '/obshti-uslovia/',
  },
  {
    text: 'Документи',
  },
]

export default ({ data }) => {
  const page = data.page;

  return (
    <>
      <GenericPage
        title={page.title}
        description={page.acf.seo_description}
        breadcrumbs={breadcrumbs}
        showBackButton={true}
        showShareTab={page.acf.show_share_buttons}
      >
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
      </GenericPage>
    </>
  )
}

//   featured_image_url
export const query = graphql`
  query ($id: String!) {
    page: wordpressWpStaticPages(id: { eq: $id }) {
      title
      slug
      date
      content
      acf {
        seo_description
        show_share_buttons
      }
    }
  }
`;
