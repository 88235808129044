import React from 'react';
import Layout from '../layout';
import { Breadcrumbs } from './breadcrumbs';
import { Link, useStaticQuery, graphql } from 'gatsby';
import SEO from '../seo';
import ShareTab from './share-tab';
import { BgWrapper } from './components';

export const GenericPage = ({
	title,
	description,
	breadcrumbs,
	children,
	showBackButton,
	showShareTab
}) => {
	const data = useStaticQuery(graphql`
		query GenericPage {
			bg: file(relativePath: { eq: "promo/promo-bg.png" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`);

	return (
		<>
			<BgWrapper bg={data.bg.childImageSharp.fluid.src}>
				<Layout>
					<SEO title={title} description={description} />
					<div className="container" style={{ paddingTop: '16rem' }}>
						{showBackButton && (
							<Link
								to={breadcrumbs[breadcrumbs.length - 2].link}
								className="link"
							>
								<span className="icon back-blue-arrow mr-1"></span> Назад
							</Link>
						)}

						<h1>{title}</h1>
						<Breadcrumbs items={breadcrumbs} />
						<hr className="normal-bottom" />
						<div className="col-12 col-lg-8 p-0 m-0 mb-5">{children}</div>
						{showShareTab && <ShareTab path={showShareTab} />}
					</div>
				</Layout>
			</BgWrapper>
		</>
	);
};
